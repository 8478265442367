<template>
  <div id="register">
    <el-form>
      <h1 class="title">找回密码</h1>
      <!-- 手机号 -->
      <el-input
        v-model="form.userPhone"
        placeholder="请填写手机号"
        prefix-icon="el-icon-phone"
        clearable
      ></el-input>
      <el-input
        placeholder="请填写图形验证码"
        v-model="form.imageCode"
        maxlength="4"
      >
        <template slot="append">
          <img
            class="securityCode"
            :src="imageBase64Data"
            @click="getImageCode"
          />
        </template>
      </el-input>
      <el-input v-model="form.securitycode" placeholder="请填写短信验证码">
        <template #append>
          <div
            v-if="!isCountDown"
            class="securityCode"
            @click="setSecuritycode()"
          >
            发送验证码
          </div>
          <div class="securityCode" v-else>{{ time }}s</div>
        </template>
      </el-input>
      <!-- 密码 -->
      <el-input
        v-model="form.password"
        placeholder="请填写密码"
        show-password
        prefix-icon="el-icon-lock"
      ></el-input>
      <!-- 确认密码 -->
      <el-input
        v-model="form.repassword"
        placeholder="请确认密码"
        show-password
        prefix-icon="el-icon-lock"
      ></el-input>

      <el-button type="primary" style="width: 100%" @click="goChangePassword()"
        >确认修改</el-button
      >
    </el-form>
    <div class="login" @click="linkLogin">
      <span>返回账号密码登录</span>
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import { getImageCode } from "@/api/setting/user";
import { goSendMobileCode, goChangePassword } from "@/api/account/account.js";
export default {
  data() {
    return {
      form: {
        userPhone: "", //手机号

        imageCode: "", // 验证码
        codeId: "", //验证码id
        password: "", //密码
        repassword: "", // 确认密码
        securitycode: "", // 手机验证码
      },
      imageBase64Data: "",
      isCountDown: false,
      time: 60,
      timer: null,
    };
  },
  mounted() {
    this.getImageCode();
  },
  methods: {
    // 修改密码
    goChangePassword() {
      if (this.form.userPhone == "") {
        this.$message.error({
          message: "请输入手机号",
        });
        return;
      }
      if (this.form.securitycode == "") {
        this.$message.error({
          message: "请输入短信验证码",
        });
        return;
      }
      if (this.form.password == "" || this.form.password.length < 6) {
        this.$message.error({
          message: "请输入不少于6位的密码",
        });
        return;
      }
      if (this.form.password != this.form.repassword) {
        this.$message.error({
          message: "两次密码不一致",
        });
        return;
      }
      let params = {
        userMobile: this.form.userPhone,
        password: this.form.password,
        code: this.form.securitycode,
      };
      goChangePassword(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push({
              path: "/mine/login",
            });
          }, 1000);
        }
      });
    },
    // 【请求】发送手机验证码
    goSendMobileCode() {
      let params = {
        phoneNumber: this.form.userPhone,
        codeId: this.form.codeId,
        imageCode: this.form.imageCode,
      };
      goSendMobileCode(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.isCountDown = true;
          this.$message({
            message: "验证码发送成功",
            type: "success",
          });
          // 倒计时
          this.timer = setInterval(() => {
            this.time = this.time - 1;
            if (this.time == 0) {
              clearInterval(this.timer);
              this.time = 60;
              this.isCountDown = false;
            }
          }, 1000);
        }
      });
    },
    // 发送验证码
    setSecuritycode() {
      if (this.form.userPhone == "" || this.form.userPhone.length != 11) {
        this.$message.error({
          message: "请填写正确的手机号（长度为11位）",
        });
      } else if (this.form.imageCode == "") {
        this.$message.error({
          message: "请输入图形验证码",
        });
      } else {
        this.goSendMobileCode();
      }
    },
    // 【请求】获取图片验证码
    getImageCode() {
      getImageCode().then((res) => {
        if (res.isSuccess == "yes") {
          this.form.imageCode = "";
          this.form.codeId = res.data.codeId;
          this.imageBase64Data = res.data.imageBase64Data;
        }
      });
    },
    // 跳转登录
    linkLogin: _.throttle(function () {
      this.$router.push({
        path: "/mine/login",
      });
    }, 1000),
  },
};
</script>


<style lang="scss" scoped>
#register {
  width: 3rem;
  height: 4rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
}

h1 {
  font-size: 0.28rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
  letter-spacing: 0.05rem;
}

.el-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login {
  margin: 0.1rem 0;
  width: 100%;
  height: 0.2rem;
  line-height: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  cursor: pointer;
  span,
  i {
    font-size: 0.12rem;
  }
}
</style>
<style lang="scss">
#register {
  .el-input-group__append {
    padding: 0 0.1rem;
    height: 100%;
  }
  .securityCode {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
